<template>
    <div></div>
</template>
<script>
export default {
    created() {
        // 获取分享链接 -- 跳转新的快速下单页面
        this.$router.push(`/${this.$route.query.language}/goods/detail/${this.$route.query.id}?back=/${this.$route.query.language}&qorder=1`)
    }
}
</script>